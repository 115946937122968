export class ticket_detail
{
    constructor() {
        this.id_company	= null;
        this.id_branch	= null;
        this.id	= null;
        this.id_ticket	= null;
        this.id_number	= null;
        this.id_product	= null;
        this.key_name	= null;
        this.name	= null;
        this.unit_price	= 0.00;
        this.quantity	= 1.00;
        this.total	= 0.00;
        this.discount_percentage	= 0.00;
        this.iva_percentage	= 0.00;
        this.note	= null;
        this.account_number	= null;
        this.status	= null;
        this.created_by	= null;
        this.created_terminal	= null;
        this.created	= null;
        this.modified_by	= null;
        this.terminal_modified	= null;
        this.modified	= null;
        this.cancelation_return	= null;
        this.cancel_date	= null;
        this.discount_date	= null;
        this.id_discount	= null;
        this.id_cancel_reason	= null;
        this.id_discount_reason	= null;
        this.id_discount_type	= null;
        this.discount_key	= null;
        this.cancel_comment	= null;
        this.discount_reference	= null;
        this.cancel_user	= null;
        this.discount_user	= null;
        this.id_register	= null;
        this.discount_import = 0.00;
        this.promo = null;
        this.promo_type = null;
        this.promo_subtype = null;
        this.original_price = null;
        this.extra = [];
    }  
}