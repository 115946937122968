<template>
    <Wizard :startIndex="startIndex" :customTabs="steps" :nextButton="{
            text: 'Siguiente',
            disabled: !nextButtonEnable
        }" :doneButton="{
            text: 'Terminar',
            icon: 'check',
            disabled: !finished
        }" @change="onChangeCurrentTab" @complete:wizard="wizardCompleted" showProgress>
        <Panel :header="steps[stepperIndex].title">
            <p v-if="steps[stepperIndex].data.min_selectable == steps[stepperIndex].data.max_selectable
                && (currentProducts ?? []).length < currentMax">
                Seleccione {{ steps[stepperIndex].data.min_selectable }} producto{{ steps[stepperIndex].data.min_selectable >
                    1 ? 's' : '' }}
            </p>
            <div class="grid p-fluid">
                <ProductButton v-for="product in steps[stepperIndex].data.detail_products"
                    @selected="selectProduct(product)" :selected="product.selected" :disabled="(currentProducts ?? []).length == currentMax &&
                        currentProducts.findIndex(x => x.id == product.id) == -1" :lg="3" :xl="3" :product="product"
                    :key="product.id" />
            </div>
        </Panel>
    </Wizard>
</template>
    
<script>
import 'form-wizard-vue3/dist/form-wizard-vue3.css';
import Wizard from 'form-wizard-vue3';
import ProductButton from "../components/ProductButton.vue";
// import { ticket_detail } from '../models/ticket_detail';
import { ticket_detail_extra } from '../models/ticket_detail_extra';
// var math = require("mathjs");
export default {
    components: { Wizard, ProductButton },
    emits: ['finishWizard', 'clean'],
    props: {
        details: {
            type: Array,
            default: () => []
        },
        product: {
            type: Object,
            default: () => { }
        }
    },
    methods: {
        wizardCompleted() {
            let extras = [];
            //BUSINESSLOGIC: A diferencia del [ProductStepper] aqui debemos hacer un producto por detalle 
            Object.keys(this.products).forEach(p => {
                this.products[p].forEach(element => {
                    let detail = this.details.find(x => x.id == element.id_pos_product_detail);
                    //LOOKAHEAD: No existe un articulo en particular para extras de un detalle nivel 2
                    let extra = new ticket_detail_extra();
                    //HARDCODE: No tenemos producto
                    extra.description = this.product.name + ' - ' + detail.name;
                    extra.name = element.name;
                    extra.id_pos_product_detail_product = element.id;
                    extra.id_product = element.id_pos_product;
                    extra.id_pos_product_detail = element.id_pos_product_detail;
                    extra.has_price = this.product.has_extra_price;
                    extra.price = extra.has_price ? element.price : 0.00;
                    extras.push(extra);
                });
            });
            
            //* Limpiamos
            this.startIndex = 0;
            this.stepperIndex = 0;
            this.products = {};
            this.steps.forEach(step => {
                step.data.detail_products.forEach(element => {
                    element.selected = false;
                });
            });
            console.log('extras', extras)
            this.$emit('finishWizard', extras);
            this.$emit('clean');
        },
        onChangeCurrentTab(index) {
            this.stepperIndex = index;
        },
        selectProduct(product) {
            //BUSINESSLOGIC: Solo seleccionamos hasta la cantidad maxima
            if (product.selected) {
                this.products[this.steps[this.stepperIndex].data.id] = this.currentProducts.filter(x => x.id != product.id);
                product.selected = false;
            } else if (this.currentProducts.length < this.currentMax) {
                this.products[this.steps[this.stepperIndex].data.id].push(product);
                product.selected = true;
            }
        }
    },
    computed: {
        finished() {
            return this.currentMax <= (this.currentProducts ?? []).length && this.currentMin >= (this.currentProducts ?? []).length;
        },
        currentMax() {
            return this.steps[this.stepperIndex].data.max_selectable;
        },
        currentMin() {
            return this.steps[this.stepperIndex].data.min_selectable;
        },
        nextButtonEnable() {
            return (this.currentProducts ?? []).length >= this.currentMin && (this.currentProducts ?? []).length <= this.currentMax;
        },
        steps() {
            return this.details.map((detail, index) => {
                return {
                    id: index,
                    title: detail.name,
                    data: detail
                }
            });
        },
        currentProducts() {
            return this.products[this.steps[this.stepperIndex].data.id];
        }
    },
    data() {
        return {
            startIndex: 0,
            stepperIndex: 0,
            products: {}
        };
    },
    watch: {
        product() {
            this.products = {};
            this.details.forEach(detail => {
                this.products[detail.id] = [];
            });
            this.$forceUpdate();
        }
    },
    mounted() {
        this.products = {};
        this.details.forEach(detail => {
            this.products[detail.id] = [];
        });
    }
};
</script>
    
<style></style>
    