<template>
  <div :class="colClass">
        <Card @click="select" :class="{
            product: !selected,
            'product-hover': !selected && !disabled,
            'product-selected': selected,
            'product-selected-hover': selected && !disabled,
            disabled: disabled
        }">
            <template #header>
                <img alt="user header" style="height: 9rem" :src="image">
            </template>
            <template #content>
                <div style="text-align: center;">
                    <strong>{{product.name}}</strong>
                    <p v-if="!free">{{formatCurrency(product.price)}}</p>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean, 
            default: false
        },
        selected: { 
            type: Boolean,
            default: false
        },
        lg: {
            type: Number,
            default: 4
        },
        xl: {
            type: Number,
            default: 4
        },
        md: {
            type: Number,
            default: 6
        },
        sm: {
            type: Number,
            default: 12
        },
        product: {
            type: Object,
            default: () => {}
        },
        free: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        colClass() {
            let classes = {};
            classes['col-12'] = true;
            classes['sm:col-' + this.sm] = true;
            classes['md:col-' + this.md] = true;
            classes['lg:col-' + this.lg] = true;
            classes['xl:col-' + this.xl] = true;
            return classes;
        },
        image() {
            return this.product.image == null ? 'https://comnplayscience.eu/app/images/notfound.png'
             : this.$config.api_route + "images/products/" + this.product.image;
        }
    },
    methods: {
        select() {
            if (!this.disabled) {
                this.$emit('selected', this.product)
            }
        },
        formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		}
    }
}
</script>

<style>
    .disabled { 
        opacity: 0.5;
    }

    .product {
        cursor: pointer;
        min-height: 12.5rem;
    }

    .product-selected {
        cursor: pointer;
        min-height: 12.5rem;
        background-color: rgb(26, 121, 69);
        color: white;
    }

    .product-hover:hover {
        background-color: rgb(50, 85, 160);
        color: white;
    }

    .product-selected-hover:hover {
        background-color: rgb(173, 10, 10);
        color: white;
    }
</style>