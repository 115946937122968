<template>
  <div class="grid">
    <Loader v-model="loading" />
    <ProductStepper :bdProducts="products" @finishWizard="addProductDetail" @clean="productStepperVisible = false; selectedProductWithDetail = null;" v-model="productStepperVisible" :product="selectedProductWithDetail"></ProductStepper>
    <PrinterDialog v-model="printerDialogVisible" :url="printer_url" ref="printer" :printers="printers" :payload="printer_data" :type="printer_type" />
    <TicketCancelDialog  v-model="cancelModalVisible" />
    <DeliveryInfoDialog v-model="deliveryInfoModalVisible" ref="deliveryInfo" :actualCustomer="actualCustomer"></DeliveryInfoDialog>
    <TicketSearchDialog @found="foundTicket" v-model="searchModalVisible" />
    <TicketValidationDialog :ticket="cancel_ticket" title="Validacion para eliminacion" event="cancelation" v-model="ticketValidationVisible" />
    <PaymentSidebar ref="paymentSidebar" :deliveryArea="delivery_area" :paymentTypes="paymentTypes" @hide="paymentVisible = false" 
    v-model="paymentVisible" :ticket="ticket" @pay="addPayment" @edit="editPayment"
    @delete="deletePayment" @save="savePayment" @saveDelivery="saveDelivery" @saveShipping="saveShipping" />
    <div class="col-12 xl:col-8">
      <LSSpreadSheet 
        v-if="isRetailExcelVisible"
        v-model="sheet"
        ref="sheet" 
        :cols_options="cols_options"
        :rows="rows"
        @add-row="rows++"
        @remove-row="rows = rows == 10 ? rows : rows - 1"
        @last-tab="rows++"
        @last-enter="rows++"
        @row-autocomplete-select="onRowAutoCompleteSelect">
      </LSSpreadSheet>
      <SalesList :tickets="unpaidTickets"
      v-if="isSaleListVisible"
      @select="selectTicket" />
      <SearchProduct
        v-if="isSearchProductVisible"
        @selectedProduct="addProduct"
        :products="products"
        :families="families"
      />
    </div>
    <div class="col-12 xl:col-4">
      <SaleType 
      v-if="isSaleTypeVisible"
      ref="saleType"
      :tables="tables"
      :waiters="waiters"
      :table="ticket.table"
      :waiter="ticket.waiter"
      :price_lists="price_lists"
      :price_list="ticket.price_list"
      :services="services"
      @on-table-selected="selectTable"
      @on-waiter-selected="selectWaiter"
      @new-customer="newCustomer"
      @change-price="changePrice"
      @on-customer-selected="selectCustomer"
      @open-delivery="openDelivery"
      :customers="customers"
      v-model="ticket.id_service_type" />
      <Ticket 
      v-if="isTicketVisible"
      ref="ticket"
      @changeIdentifier="changeIdentifier"
      @new="newTicket"
      @delete="deleteTicket"
      @pay-ticket="openPayment"
      @prepare-ticket="savePrepare"
      @register-ticket="registerTicket"
      @delete-item="deleteProduct" 
      :ticket="ticket" 
      :deliveryAreas="delivery_area" />
    </div>
  </div>
</template>

<script>

//* Classes
import { ticket } from "../models/ticket";
import { customer } from "../models/customer";

//* Data
import { basicData } from "../data/basicData";
import EventBus from "@/AppEventBus";
import formMixin from "../mixins/form";

//*	Components
import SalesList from "../components/SalesList.vue";
import SearchProduct from "../components/SearchProduct.vue";
import Ticket from "../components/Ticket.vue";
import SaleType from "../components/SaleType.vue";
import PaymentSidebar from "../components/PaymentSidebar.vue";
import TicketCancelDialog from "../components/TicketCancelDialog.vue";
import TicketSearchDialog from "../components/TicketSearchDialog.vue";
import TicketValidationDialog from "../components/TicketValidationDialog.vue";
import DeliveryInfoDialog from "../components/DeliveryInfoDialog.vue";
import ProductStepper from "../components/ProductStepper.vue";


import Loader from "../components/general/Loader.vue";
var math = require("mathjs");

import { printer_payload } from '../models/printer_payload'

import { fillObject, sleep } from '../utilities/General';
import PrinterDialog from './PrinterDialog.vue';
export default {
  components: { ProductStepper, PrinterDialog, TicketSearchDialog,TicketValidationDialog,DeliveryInfoDialog,
  TicketCancelDialog, PaymentSidebar, Loader, SalesList, SaleType, SearchProduct, Ticket },
  mixins: [formMixin],
  data() {
    return {
      printer_url: null,
      waiters: [],
      cancel_ticket: new ticket(),
      productStepperVisible: false,
      ticketValidationVisible: false,
      searchModalVisible: false,
      cancelModalVisible: false,
      deliveryInfoModalVisible: false,
      printerDialogVisible: false,
      sheet: null,
      layoutPOS: 'basic',
      paymentVisible: false,
      active: 0,
      loading: false,
      selectedCountries: null,
      printer_type: 'payment',
      printer_data: {},
      families: [],
      products: [],
      customers: [],
      tickets: [],
      tables: [],
      price_lists: [],
      paymentTypes: [],
      printers: [],
      services: {},
      delivery_area: [],
      ticket: new ticket(),
      actualCustomer: new customer(),
      selectedProductWithDetail: null,
      rows: 10,
      cols_options: {
        A: { w: '12%', n: 'SKU', t: 'autocomplete', o: [] },
        B: { w: '24%', n: 'Nombre', t: 'text-resize', d: true},
        C: { w: '12%', n: 'Cantidad', t: "number", a: "center", v: 1 },
        D: { w: '12%', n: 'Precio', t: "currency", a: "center", d: true },
        E: { w: '12%', n: 'Total', t: "currency", a: "center", d: true, f: "C{row} * D{row}" },
      }
    };
  },
  themeChangeListener: null,
  async mounted() {
    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);
    EventBus.on("cancel-ticket", this.cancelTicket);
    EventBus.on("test-printer", this.testPrinter);
    EventBus.on("open-cash-drawer", this.openCashDrawer);
    EventBus.on("sale-history", this.saleHistory);
    EventBus.on("orders", this.showOrders);

    this.printer_url = this.$config.printer_url;
    
    this.ticket = new ticket(this.session);
    this.loading = true;
    try {
      let basicRetriever = new basicData(this.session);

      try {
        this.register = await basicRetriever.getSessionRegister();
        this.tickets = await basicRetriever.getTickets(this.register.id);
      } catch (error) {
        this.showError("No se ha abierto la caja, favor de realizar el registro de efectivo");
        this.$router.push("/caja");
      }      
      this.families = await basicRetriever.getFamilies();
      this.products = (await basicRetriever.getProducts()).map(x => {
        return {
          original_catalog_price: x.price,
          ...x
        }
      });
      
      let codebars = this.products.filter(x => x.codebar).map(x => x.codebar);
      this.cols_options.A.o = codebars;
      this.paymentTypes = await basicRetriever.getPaymentTypes();
      this.tables = await basicRetriever.getTables();
      this.waiters = await basicRetriever.getWaiters();
      this.customers = await basicRetriever.getCustomers();
      this.printers = await basicRetriever.getPrinters();
      this.price_lists = await basicRetriever.getPriceList();
      this.delivery_area = await basicRetriever.getDeliveryArea();
      this.services = await basicRetriever.getServices();
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  },
  beforeMount() {
    this.layoutPOS = this.$storage.getStorageSync('tpv-layout') == null ? 'basic' : this.$storage.getStorageSync('tpv-layout');
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
    EventBus.off("cancel-ticket", this.cancelTicket);
  },
  methods: {
    changeIdentifier(payload) {
      this.ticket.identifier = payload;
    },
    saveDelivery(payload) {
      this.ticket.id_delivery_area = payload; 
    },
    saveShipping(payload) {
      this.ticket.shipping = payload;
    },
    openDelivery() {
      this.deliveryInfoModalVisible = true;
    },
    async changePrice(payload) { 
      this.loading = true
      try {
        await sleep(2000);

        this.ticket.price_list = payload;
      //Modificamos todos los precios
      this.showWarning("Se ha cambiado el tipo de venta, esto afectara a los PRECIOS del ticket", {
        title: 'Cambio de Precio',
        toastBackgroundColor: '#fc901c'
      });

      if(payload == 0) {
        this.products.forEach(x => {
          x.price = x.original_catalog_price
        });
      } else {          
        this.products.forEach(x => {
          x.price = x.list_prices.find(x => x.id_pos_price_list == payload) 
          ? x.list_prices.find(x => x.id_pos_price_list == payload).price
          : x.original_catalog_price;
        });
      }

      this.ticket.items.forEach(item => {
          let product = this.products.find(product => product.id == item.id_product);
          item.unit_price = payload == 0 
                          ? product.original_catalog_price 
                          : product.list_prices.find(x => x.id_pos_price_list == payload)
                          ? product.list_prices.find(x => x.id_pos_price_list == payload).price
                          : product.original_catalog_price;
          item.original_price = item.unit_price;
          item.subtotal = math.chain(item.quantity)
                              .multiply(item.unit_price)
                              .done();
          item.iva_amount = math.chain(item.subtotal)
                                .multiply(item.iva_percentage / 100)
                                .done();
          item.total = math.chain(item.subtotal)
                            .add(item.iva_amount)
                            .done();
      });
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    foundTicket(ticket) {
      this.cancel_ticket = ticket;
      this.ticketValidationVisible = true;
    },
    testPrinter() {
      this.printer_type = "test";
      this.printerDialogVisible = true;
    },
    openCashDrawer() { 
      this.printer_type = "cashier/open";
      this.printerDialogVisible = true;
    },
    saleHistory() {
      this.$router.push('/historial');
    },
    showOrders() {
      this.$router.push('/pedidos');
    },
    cancelTicket() {
      this.searchModalVisible = true;
    },
    onRowAutoCompleteSelect(payload) {
      if (payload.col == "A") {
        if ((payload.value ?? "").trim() != "") {
          let product = this.products.find(x => x.codebar == payload.value);
          this.sheet["B" + payload.row].v = product == null ? null : product.name;
          this.sheet["D" + payload.row].v = product == null ? 0.00 : product.price;
          // this.rows = product == null ? this.rows : this.rows + 1;
          if (product) this.$refs.sheet.goDown();
        }
      }
    },
    selectCustomer(customer) {
      this.ticket.id_customer = customer.id;
      if (this.ticket.id_customer == null) {
        this.$refs.deliveryInfo.clear();
      }else {
        this.$refs.paymentSidebar.setDeliveryArea(customer.id_pos_delivery_area);
      }
    },
    newCustomer(customer) {
      this.customers.push(customer);
    },
    selectTable(table) {
      this.ticket.id_table = table == null ? null : table.id;
      this.ticket.table_name = table == null ? null : table.name;
      this.ticket.table = table == null ? null : table;
    },
    selectWaiter(waiter) {
      this.ticket.id_waiter = waiter == null ? null : waiter.id;
      this.ticket.waiter_name = waiter == null ? null : waiter.name;
      this.ticket.waiter = waiter == null ? null : waiter;
    },
    deletePayment(payment) { 
      let id_number = payment.id_number;
      this.ticket.payments = this.ticket.payments.filter(x => x.id_number != payment.id_number);
      this.ticket.payments.forEach(x => {
        if (x.id_number > id_number) 
          x.id_number = x.id_number - 1;
      });
    }, 
    addPayment(payment) {
      payment.id_number = this.ticket.payments.length + 1;
      this.ticket.payments.push(payment);
    },
    editPayment(payment) {
      let index = this.ticket.payments.findIndex(x => x.id_number == payment.id_number);
      this.ticket.payments[index] = payment;
    },
    async savePayment(response) {
       try {
          this.ticket.is_invoiceable = false;
          if (response != null) 
            this.ticket.is_invoiceable = response.event == "invoice";
          
          if (this.ticket.id_service_type == 3
            && this.ticket.id_customer == null) {
            throw "Favor de agregar un cliente"
          }

          if (this.ticket.id_service_type == 1
            && this.ticket.id_table == null) {
            throw "Favor de agregar una mesa"
          }

          this.paymentVisible = false;
          this.loading = true;
          let newTicket = await this.ticket.pay();
          this.ticket = fillObject(this.ticket, newTicket);

          let index = this.tickets.findIndex(x => x.id == this.ticket.id);
          if (index >= 0)
          {
            this.tickets[index].is_paid = true;
          }
          //Imprimimos el ticket
          let printer_data = new printer_payload();
          printer_data.name = this.ticket.branch_name;
          printer_data.taxInfo = this.ticket.branch_tax_info;
          printer_data.address = this.ticket.branch_address;
          printer_data.email = this.ticket.branch_email;
          printer_data.date = this.ticket.date;
          printer_data.id = this.ticket.id_number.toString();
          printer_data.footer = "Gracias por su compra!";
          printer_data.phoneNumber = this.ticket.branch_phone_number;
          printer_data.subtotal = this.ticket.subtotal;
          printer_data.total = this.ticket.total + this.ticket.shipping;
          printer_data.shipping = this.ticket.shipping;
          printer_data.taxes = this.ticket.iva;
          printer_data.identifier = this.ticket.identifier;
          if (this.ticket.id_customer) {
            let customer = this.customers.find(x => x.id == this.ticket.id_customer);
            printer_data.customerInfo.address = customer.address;
            printer_data.customerInfo.email = customer.email;
            printer_data.customerInfo.name = customer.name;
            printer_data.customerInfo.phoneNumber = customer.phone;
            printer_data.customerInfo.taxInfo = customer.rfc;
            printer_data.customerInfo.municipality = customer.municipality;
            printer_data.customerInfo.postalCode = customer.postalCode;
            printer_data.customerInfo.suburb = customer.suburb;
            printer_data.customerInfo.state = customer.state;
            printer_data.customerInfo.country = customer.country;
          }
          this.ticket.items.forEach(item => {
            printer_data.products.push({
              quantity: item.quantity, 
              description: item.name, 
              sku: item.key_name, 
              price: item.unit_price, 
              total: item.total,
              extra: item.extra.map(x => {
                return { 
                  description : x.description,
                  name: x.name
                }
              })
            });
          });
          this.ticket.payments.forEach(payment => {
            printer_data.change = printer_data.change + payment.amount;
            printer_data.payments.push({
              name: payment.name,
              amount: payment.amount
            });
          });
          printer_data.change = printer_data.change - (this.ticket.total + this.ticket.shipping);
          printer_data.openCashDrawer = true;
          this.printer_data = printer_data;
          this.printer_type = "payment";

          let cashier_printer = this.printers.find(x => x.is_cashier_default == true);
          if (cashier_printer) {
            try {
              await this.$refs.printer.print(cashier_printer, printer_data, this.$config.printer_url, "payment");
            } catch (error) {
              console.log(error);
            }
          }else {
            this.printerDialogVisible = true;
          }
          //* Modificamos el area del cliente
          if (this.customers.find(c => c.id == this.ticket.id_customer)) {
              this.customers[this.customers.findIndex(c => c.id == this.ticket.id_customer)].id_delivery_area = this.ticket.id_delivery_area;
          }
          this.newTicket();
        } catch (error) {
          this.showError(error);
        } finally {
          this.loading = false;
        }
    },
    async savePrepare() {
       try {
          this.loading = true;
          if (this.ticket.prepare_status == 1)
          {
            if (this.ticket.items.filter(x => x.prepare_status == 1).length == 0) {
              throw "El ticket ya esta siendo preparado";
            }
          }
          if (this.ticket.prepare_status == 2)
          {
            if (this.ticket.items.filter(x => x.prepare_status == 1).length == 0) {
              throw "El ticket ya fue preparado";
            }
          }
          
          if (this.ticket.id_service_type == 3
          && this.ticket.id_customer == null) {
            throw "Favor de agregar un cliente"
          }

          if (this.ticket.id_service_type == 1
          && this.ticket.id_table == null) {
            throw "Favor de agregar una mesa"
          }

          if (this.ticket.items.length == 0)
            throw "Favor de agregar un producto";

          let updated = this.ticket.id == null ? false : true;
          let newTicket = await this.ticket.prepare();
          this.ticket = fillObject(this.ticket, newTicket);

          if(updated) {
            let index = this.tickets.findIndex(x => x.id == newTicket.id);
            this.tickets[index] = newTicket;
          }else 
            this.tickets.push(newTicket);

          // let index = this.tickets.findIndex(x => x.id == this.ticket.id);
          // if (index >= 0)
          //   this.tickets[index] = fillObject(this.tickets[index], newTicket);
          //Imprimimos el ticket
          let printer_data = new printer_payload();
          printer_data.name = this.ticket.branch_name;
          printer_data.taxInfo = this.ticket.branch_tax_info;
          printer_data.address = this.ticket.branch_address;
          printer_data.email = this.ticket.branch_email;
          printer_data.date = this.ticket.date;
          printer_data.id = this.ticket.id_number.toString();
          printer_data.footer = "Gracias por su compra!";
          printer_data.phoneNumber = this.ticket.branch_phone_number;
          printer_data.subtotal = this.ticket.subtotal;
          printer_data.total = this.ticket.total + this.ticket.shipping;
          printer_data.shipping = this.ticket.shipping;
          printer_data.taxes = this.ticket.iva;
          printer_data.identifier = this.ticket.identifier;
          if (this.ticket.id_customer) {
            let customer = this.customers.find(x => x.id == this.ticket.id_customer);
            printer_data.customerInfo.address = customer.address;
            printer_data.customerInfo.email = customer.email;
            printer_data.customerInfo.name = customer.name;
            printer_data.customerInfo.phoneNumber = customer.phone;
            printer_data.customerInfo.taxInfo = customer.rfc;
            printer_data.customerInfo.municipality = customer.municipality;
            printer_data.customerInfo.postalCode = customer.postalCode;
            printer_data.customerInfo.suburb = customer.suburb;
            printer_data.customerInfo.state = customer.state;
            printer_data.customerInfo.country = customer.country;
          }
          this.ticket.items.forEach(item => {
            printer_data.products.push({
              quantity: item.quantity, 
              description: item.name, 
              sku: item.key_name + " - " + (item.note ?? ''),
              price: item.unit_price, 
              total: item.total,
              extra: item.extra.map(x => {
                return { 
                  description : x.description,
                  name: x.name
                }
              })
            });
          });
          this.printer_data = printer_data;
          this.printer_type = "preparation";
          
          let kitchen_printer = this.printers.find(x => x.is_kitchen_default == true);
          if (kitchen_printer) {
            try {
              await this.$refs.printer.print(kitchen_printer, printer_data, this.$config.printer_url, "preparation");
            } catch (error) {
              console.log(error);
              this.printerDialogVisible = true;
            }
          }else {
            this.printerDialogVisible = true;
          }
          this.newTicket();
        } catch (error) {
          this.showError(error);
        } finally {
          this.loading = false;
        }
    },
    addProductDetail(product) {
      product.id_number = this.ticket.items.length + 1;
      this.ticket.items.push(product);
    },
    addProduct(product) {
      let internal_product = this.products.find(x => x.id == product.id_product);
      if (internal_product.details.length > 0) {
        this.selectedProductWithDetail = internal_product;
        this.productStepperVisible = true;
      }else {
        product.id_number = this.ticket.items.length + 1;
        this.ticket.items.push(product);
      }
    },
    deleteProduct(product) {
      let id_number = product.id_number;

      if (!product.id) {  
        this.ticket.items = this.ticket.items.filter(
          (x) => x.id_number != product.id_number
        );
      }

      this.ticket.items.forEach((x) => {
          if (x.id_number > id_number && !x.deleted) {
            x.id_number = x.id_number - 1;
          }
        });
    },
    selectTicket(ticket) {
      if (ticket.id != this.ticket.id) {
        this.ticket = fillObject(this.ticket, ticket);
        this.$refs.ticket.setIdentifier(this.ticket.identifier);
        this.$refs.saleType.fillCustomer(this.ticket.id_customer);
      }
    },
    newTicket() {
      this.ticket = new ticket(this.session);
      this.$refs.saleType.fillCustomer(null);
      this.$refs.ticket.clearIdentifier();
    },
    async deleteTicket() {
      try {
          if (this.ticket.id) {
            await this.ticket.delete();
            this.tickets = this.tickets.filter(x => x.id != this.ticket.id);
          }
          this.newTicket();
          this.showSuccess("Ticket eliminado con exito")
        } catch (error) {
          this.showError(error);
        } finally {
          this.loading = false;
        }
    },
    async registerTicket(payload) {
      try {
        if (this.ticket.id_service_type == 3
        && this.ticket.id_customer == null) 
          throw "Favor de agregar un cliente"
        
        if (this.ticket.id_service_type == 1
        && this.ticket.id_table == null) 
          throw "Favor de agregar una mesa"

        if (this.ticket.items.length == 0)
          throw "Favor de agregar un producto";

        this.loading = true;
        this.ticket.id_register = this.register.id;
        //* Recalculamos
        this.ticket.iva = 
        this.ticket.items
            .filter(x => !x.deleted)
            .reduce((a, b) => {
                let itemTaxes = math
                  .chain(b.quantity)
                  .multiply(b.unit_price)
                  .multiply(b.iva_percentage / 100)
                  .done();
                return math.chain(a).add(itemTaxes).done();
              }, 0);
        this.ticket.discount_import = payload.discount;
        this.ticket.subtotal = payload.subtotal;
        this.ticket.total = payload.total;
        let updated = this.ticket.id == null ? false : true;
        let newTicket = await this.ticket.register();
        this.ticket = fillObject(this.ticket, newTicket);
        if(updated) {
          let index = this.tickets.findIndex(x => x.id == newTicket.id);
          this.tickets[index] = newTicket;
        }else 
          this.tickets.push(newTicket);
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    openPayment(payload) {
      try {
        if (this.ticket.id_service_type == 3 && this.ticket.id_customer == null) {
          throw "Favor de agregar un cliente"
        }

        if (this.ticket.id_service_type == 1
        && this.ticket.id_table == null) {
          throw "Favor de agregar una mesa"
        }
        
        if (this.ticket.items.length == 0)
          throw "Favor de agregar un producto";


        this.ticket.discount_import = payload.discount;
        this.paymentVisible = true;
      } catch (error) {
        this.showError(error);
      }
    }
  },
  computed: {
    isSaleTypeVisible() {
      return true;
    },
    isTicketVisible() {
      return true;
    },
    isSaleListVisible() {
      return true;
    },
    isSearchProductVisible() {
      return true;
    },
    isRetailExcelVisible() {
      return false;
    },
    unpaidTickets() {
      return this.tickets.filter(x => !x.is_paid)
    }
  },
  watch: {
    ["ticket.id_customer"](newValue) {
      if (newValue) {
        this.actualCustomer = this.customers.find(x => x.id == newValue);
      }
    }
  }
};
</script>

<style>
#tabmenu {
  overflow-x: scroll;
}

.p-listbox-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.p-listbox-list-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
</style>