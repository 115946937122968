
import { DateTime } from "luxon";

export default function (value, type) {
    let year = new Date(value).getFullYear();
    let month = new Date(value).getMonth() + 1;
    let day = new Date(value).getDate();
    let hours = new Date(value).getHours();
    let minutes = new Date(value).getMinutes();
    let sec = new Date(value).getSeconds();
  if (type == "date") {
        return DateTime.local(year, month, day, hours, minutes).toLocaleString(DateTime.DATE_FULL);
  }else if(type == "time"){
      return DateTime.local(year, month, day, hours, minutes,sec).toFormat("h:mm:ss a");
  }else {
      return "N/D";
  }
}
