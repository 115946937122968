export class ticket_detail_extra
{
    constructor() {
        this.id_company = null;
        this.id_branch = null;
        this.id = null;
        this.id_pos_ticket_detail = null;
        this.id_product = null;
        this.id_pos_product_detail = null;
        this.id_pos_product_detail_product = null;
        this.description = null;
        this.name = null;
        this.has_price = false;
        this.price = null;
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
    }  
}