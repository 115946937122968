<template>
  <div class="card">
    <SpeedDial :model="items" direction="down"/>
    <ConfirmPopup></ConfirmPopup>
    <OverlayPanel ref="itemOptions" :dismissable="true">
      <div>
        <Button
          @click="deleteItem"
          icon="pi pi-times"
          label="Eliminar"
          class="p-button-danger"
        />
      </div>
      <br />
      <InputNumber
        id="horizontal"
        v-model="selectedItem.quantity"
        showButtons
        buttonLayout="horizontal"
        :step="1"
        :min="1"
        decrementButtonClass="p-button-info"
        incrementButtonClass="p-button-info"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
      />
      <br /><br />
      <Textarea
        placeholder="Escribe una nota..."
        v-model="selectedItem.note"
        :autoResize="true"
        rows="2"
        cols="32"
      />
    </OverlayPanel> 
    <h5 style="text-align: center" v-if="ticket.order_number">Ticket #{{ticket.order_number}}</h5>
    <h5 style="text-align: center" v-else>Nuevo Ticket</h5>
    <InputText placeholder="Identificador de ticket..." style="width: 100%;" v-model="identifier"></InputText>
    <Listbox
      v-model="selectedItems"
      :options="visibleItems"
      :multiple="false"
      :filter="true"
      filterPlaceholder="Buscar..."
      optionLabel="product"
      optionValue="id_number"
      :filterFields="['name']"
      listStyle="max-height:20rem"
      style="width: 100%"
      class="orderitems"
    >
      <template #option="slotProps">
        <div
          style="width: 100%"
          @click="openItemOptions($event, slotProps.option)"
        >
          <Chip
            :class="{
              'custom-chip': slotProps.option.prepare_status == null,
              'preparing-chip': slotProps.option.prepare_status == 1,
              'mr-2': true
            }"
            :label="slotProps.option.id_number.toString()"
          />
          {{ slotProps.option.name }}
          <Badge :value="'x ' + slotProps.option.quantity" class="mr-2"></Badge>
          <img v-if="slotProps.option.promo" v-tooltip.top="'Se aplico ' + slotProps.option.promo_subtype + ' de ' + slotProps.option.promo" src="/images/tpv/descuento.png" style="width: 20px" alt="discount">
          <Chip :label="formatCurrency(slotProps.option.unit_price)" />
          <ul>
            {{ extra }}
            <li style="font-size: smaller;" v-for="extra in slotProps.option.extra" :key="extra.name + '-' + extra.description">
              {{ extra.description + ' - ' }} <b>{{extra.name}}</b> <span style="margin-left:0.2rem;" v-if="extra.has_price">{{formatCurrency(extra.price)}}</span>
            </li>
          </ul>
        </div>
      </template>
    </Listbox>
    
    <hr />
    <div class="grid">
      <div v-if="subtotal != total" style="text-align: center" class="col-6">SubTotal:</div>
      <div v-if="subtotal != total" style="text-align: center" class="col-6">
        {{ formatCurrency(subtotal) }}
      </div>
      <div v-if="taxes > 0" style="text-align: center" class="col-6">Impuestos:</div>
      <div v-if="taxes > 0" style="text-align: center" class="col-6">
        {{ formatCurrency(taxes) }}
      </div>
      <div v-if="discount > 0" style="text-align: center" class="col-6">Descuento:</div>
      <div v-if="discount > 0" style="text-align: center" class="col-6">
        {{ formatCurrency(discount) }}
      </div>
      <div v-if="shipping > 0" style="text-align: center" class="col-6">Envio:</div>
      <div v-if="shipping > 0" style="text-align: center" class="col-6">
        {{ formatCurrency(shipping) }}
      </div>
      <div style="text-align: center" class="col-6"><b>Total:</b></div>
      <div style="text-align: center" class="col-6">
        {{ formatCurrency(total) }}
      </div>
      <div class="col-12">
        <Button
          icon="pi pi-save"
          class="p-button-success"
          style="width: 100%"
          label="Guardar"
          @click="save"
        />
      </div>
      <div class="col-6">
        <Button icon="pi pi-send" @click="prepare" style="width: 100%" label="Preparar" />
      </div>
      <div class="col-6">
        <Button icon="pi pi-money-bill" @click="pay" style="width: 100%" label="Pagar" />
      </div>
    </div>
  </div>
</template>

<script>
import { ticket_detail } from "../models/ticket_detail.js";
import formatCurrency from "../utilities/formatCurrency.js";
require('../utilities/ARRAY_UTILITIES.js');
var math = require("mathjs");
export default {
  emits: ["changeIdentifier"],
  expose: ["clearIdentifier", "setIdentifier"],
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
    deliveryAreas: {
      type: Array,
      default: () => [ ]
    }
  },
  watch: {
    identifier(value) {
      this.$emit('changeIdentifier', value);
    },
    ["selectedItem.quantity"](newValue) {
      this.selectedItem.subtotal = math.chain(newValue)
                                    .multiply(this.selectedItem.unit_price)
                                    .done();      
      this.selectedItem.iva_amount = math.chain(this.selectedItem.subtotal)
                                          .multiply(this.selectedItem.iva_percentage / 100)
                                          .done(); 
      this.selectedItem.total = math.chain(this.selectedItem.subtotal)
                                    .add(this.selectedItem.iva_amount)
                                    .done();
                                    
    },
    // ["ticket.items"]: {
    //   handler(newItems) {
    //     // PROMOCIONES
    //     newItems.filter(newItem => newItem.promo_subtype == "2X1");
    //   },
    //   deep: true
    // }
  },
  computed: {
    ["2X1_Products"]() {
      let promos = {
        quantity : 0,
        discount : 0,
        discountAmount: 0
      };
      let innerItems = [...this.ticket.items].map(x => {
        return {
          discounted: 0,
          ...x
        }
      });
      [...new Set(this.ticket.items.map(item => { 
        if (item.promo_subtype == "2X1")
          return item.promo
      }))]
      .forEach(item => {
        if (item) {
          let products = [...new Set(this.ticket.items.map(insiderItem => { 
            if (insiderItem.promo == item)
              return insiderItem.name
          }))];

          promos[item] = {};
          products.forEach(product => {
            let quantity = this.ticket.items
                  .filter(a => a.name == product)
                  .reduce((a, b) => {
                    return a + b.quantity;
                  }, 0);
            let price = this.ticket.items.find(x => x.name == product).unit_price;
            promos.quantity += quantity;
            promos[item][product] = {
              quantity: quantity,
              price: price,
              discount: 0
            }
          });
        }
      });
      
      promos.discount = math.chain(promos.quantity).divide(2).floor().done(); 

      for (let index = 0; index < promos.discount; index++) {
         //Buscamos el menor
         let otherItems = innerItems.filter(x => x.discounted != x.quantity);
         let minUnitPrice = otherItems.hasMin("unit_price");
         minUnitPrice.discounted = minUnitPrice.discounted + 1;
         promos.discountAmount = math.chain(promos.discountAmount).add(minUnitPrice.unit_price).done();
      }
      return promos;
    },
    visibleItems() {
      return this.ticket.items.filter(x => !x.deleted);
    },
    subtotal() {
      let subtotal = this.visibleItems.reduce((a, b) => {
        var itemSubtotal = math
        .chain(b.quantity)
        .multiply(b.original_price ?? 0.00)
        .done();
        return math.chain(a).add(itemSubtotal).done();
      }, 0);
      return subtotal ? subtotal : 0.0;
    },
    total() {
      let total = this.visibleItems.reduce((a, b) => {
        var itemSubtotal = math
        .chain(b.quantity ?? 0.00)
        .multiply(b.unit_price ?? 0.00)
        .done();
        var itemTaxes = math
        .chain(b.quantity ?? 0.00)
        .multiply(b.unit_price ?? 0.00)
        .multiply(b.iva_percentage / 100)
        .done();
        return math.chain(a)
        .add(itemSubtotal ?? 0.00)
        .add(itemTaxes ?? 0.00) 
        .done();
      }, 0);
      //Descuentos
      //2 X 1
      total = math.chain(total)
      .subtract(this["2X1_Products"].discountAmount)
      .add(this.shipping)
      .done();
      return total ? total : 0.0;
    },
    shipping() {
      if (this.ticket.id_service_type == 3) {
        let area = this.deliveryAreas.find(x => x.id == this.ticket.id_delivery_area);
        return area ? area.price : 0;
      } else {
        return 0;
      }
    },
    discount() {
      let discount = this.visibleItems.reduce((a, b) => {
        var itemSubtotal = math
        .chain(b.quantity)
        .multiply(b.unit_price ?? 0.00)
        .done();

        var itemSubtotalOriginal = math
        .chain(b.quantity)
        .multiply(b.original_price ?? 0.00)
        .done();

        var itemTaxes = math
        .chain(b.quantity)
        .multiply(b.unit_price ?? 0.00)
        .multiply(b.iva_percentage / 100)
        .done();

        var itemTaxesOriginal = math
        .chain(b.quantity)
        .multiply(b.original_price ?? 0.00)
        .multiply(b.iva_percentage / 100)
        .done();

        var total = math.chain(a)
        .add(itemSubtotal ?? 0.00)
        .add(itemTaxes ?? 0.00)
        .subtract(itemSubtotalOriginal ?? 0.00)
        .subtract(itemTaxesOriginal ?? 0.00)
        .done();
        return total;
      }, 0);
      discount = math
                  .chain(discount)
                  .add(this["2X1_Products"].discountAmount)
                  .done();
      return discount ? discount : 0.0;
    },
    taxes() {
      let taxes = this.visibleItems.reduce((a, b) => {
        var itemTaxes = math
        .chain(b.quantity)
        .multiply(b.unit_price)
        .multiply(b.iva_percentage / 100)
        .done();
        return math.chain(a).add(itemTaxes).done();
      }, 0);
      return taxes ? taxes : 0.0;
    },
  },
  methods: {
    formatCurrency: formatCurrency,
    clearIdentifier() { 
      this.identifier = null;
    },
    setIdentifier(value) {
      this.identifier = value;
    },
    openItemOptions(event, item) {
      this.selectedItem = item;
      this.$refs.itemOptions.toggle(event);
    },
    save() {
      this.$emit("register-ticket", {
        ticket: this.ticket,
        subtotal: this.subtotal,
        discount: this.discount,
        taxes: this.taxes,
        total: this.total
      })
    },
    pay() {
      this.$emit("pay-ticket", {
        ticket: this.ticket,
        discount: this.discount
      })
    },
    prepare() {
      this.$emit("prepare-ticket", this.ticket)
    },
    deleteItem() {
      if (this.selectedItem.id) 
        this.selectedItem.deleted = true;
      this.$emit("delete-item", this.selectedItem);
      this.selectedItems = null;
      this.$refs.itemOptions.hide();
    },
  },
  data() {
    return {
      identifier: null,
      selectedItems: null,
      selectedItem: new ticket_detail(),
      items: [
                {
                    label: 'New',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.$emit("new")
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: (event) => {
                        console.log(event)
                        this.$confirm.require({
                            target: event.originalEvent.target,
                            message: '¿Estas seguro de eliminar la venta?',
                            icon: 'pi pi-exclamation-triangle',
                            accept: () => {
                                this.$emit('delete')
                            },
                            reject: () => {
                                //callback to execute when user rejects the action
                            }
                        });
                    }
                }
            ],
    };
  },
};
</script>

<style>
.p-chip.custom-chip {
  background: rgb(121, 121, 121);
  color: var(--primary-color-text);
}

.p-chip.preparing-chip {
  background: rgb(131, 231, 92);
  color: var(--primary-color-text);
}
</style>