<template>
    <Dialog v-model:visible="localValue" :maximizable="true" :style="{ width: '90%' }" :modal="true" :closable="true">
      <template #header>
        <h4 v-if="product">{{product.name}}</h4>
      </template>
      <div>
        <Wizard 
            :startIndex="startIndex" 
            :customTabs="steps" 
            :nextButton="{
                text: 'Siguiente',
                disabled: !nextButtonEnable
              }"
            :doneButton="{
                text: 'Terminar',
                icon: 'check',
                disabled: !finished
              }" 
            @change="onChangeCurrentTab"
            @complete:wizard="wizardCompleted"
            showProgress>
            <Panel>
                <template #header>
                  <strong>{{ steps[stepperIndex].title }}</strong>
                  <p v-if="steps[stepperIndex].data.min_selectable == 0">
                      Opcional
                  </p>
                  <p v-else-if="steps[stepperIndex].data.min_selectable > 0 && steps[stepperIndex].data.min_selectable == steps[stepperIndex].data.max_selectable">
                    Seleccione minimo {{ steps[stepperIndex].data.min_selectable }} opcion(es)
                  </p>
                  <p v-else>
                    Seleccione entre {{ steps[stepperIndex].data.min_selectable }} y {{ steps[stepperIndex].data.max_selectable }}  opcion(es)
                  </p>
                </template>
                <div v-if="steps[stepperIndex].data.last_level == 2">
                  <ProductWizard v-if="steps[stepperIndex].data != null" 
                  :key="steps[stepperIndex].key"
                  :details="steps[stepperIndex].data.details" 
                  :product="steps[stepperIndex].data"
                  @finishWizard="innerStepFinished"
                  @clean="innerStepCleaned">
                  </ProductWizard>
                </div>
                <div v-else>
                  <div class="grid p-fluid">
                      <ProductButton v-for="product in steps[stepperIndex].data.detail_products" 
                      @selected="selectProduct(product)" 
                      :selected="product.selected"
                      :free="!steps[stepperIndex].data.has_extra_price"
                      :disabled="currentProducts.length == currentMax && 
                      currentProducts.findIndex(x => x.id == product.id) == -1"
                      :lg="3" :xl="3"
                      :product="product" 
                      :key="product.id" />
                  </div>
                </div>
            </Panel>
        </Wizard>
      </div>
    </Dialog>
  </template>
    
  <script>
    import 'form-wizard-vue3/dist/form-wizard-vue3.css';
    import Wizard from 'form-wizard-vue3';
    import ProductWizard from "../components/ProductWizard.vue";
    import ProductButton from "../components/ProductButton.vue";
    import { ticket_detail } from '../models/ticket_detail';
    import { ticket_detail_extra } from '../models/ticket_detail_extra';
    var math = require("mathjs");
  export default {
    components: { Wizard, ProductButton, ProductWizard },
    props: {
      modelValue: {
        required: true,
      },
      product: {
        type: Object,
        default: () => {}
      },
    },
    methods: {
        innerStepFinished(data) {
          this.products[this.steps[this.stepperIndex].data.id] = data;
          if (this.steps.length == (this.stepperIndex + 1)) {
            this.wizardCompleted();
          }
          else
            this.stepperIndex =+ 1;
        },
        wizardCompleted() {
            //TODO: Armamos la respuesta, emitimos, limpiamos y luego cerramos
            //* Armamos y emitimos respuesta
            let detail = new ticket_detail();
            detail.promo = this.product.promo;
            detail.promo_subtype = this.product.promo_subtype;
            detail.promo_type = this.product.promo_type;
            detail.unit_price = this.product.price;
            detail.quantity = 1;
            detail.id_product = this.product.id;
            detail.name = this.product.name;
            detail.key_name = this.product.key_name;
            detail.original_price = this.product.original_price ?? this.product.price;
            detail.subtotal = this.product.price;
            Object.keys(this.products).forEach(p => {
                this.products[p].forEach(element => {
                    //LOOKAHEAD: Aqui puede haber algunos que ya sean extras, no hace falta cambiar nada
                    if (this.steps.length > 1) {
                      element.id_pos_product_detail_product = element.id;
                      element.id = null;
                      element.has_price = this.product.details.find(x => x.id == element.id_pos_product_detail).has_extra_price;
                      element.price = element.has_price ? element.price : 0.00;
                      element.description = this.product.details.find(x => x.id == element.id_pos_product_detail).name;
                      detail.extra.push(element);
                    }else{
                      let extra = new ticket_detail_extra();
                      extra.description = this.product.details.find(x => x.id == element.id_pos_product_detail).name;
                      extra.name = element.name;
                      extra.id_pos_product_detail_product = element.id;
                      extra.id_product = element.id_pos_product;
                      extra.id_pos_product_detail = element.id_pos_product_detail;
                      extra.has_price = this.product.details.find(x => x.id == element.id_pos_product_detail).has_extra_price;
                      extra.price = extra.has_price ? element.price : 0.00;
                      detail.extra.push(extra);
                    }
                });
            });
            //* Limpiamos
            this.products = {};
            this.steps.forEach(step => {
                step.data.detail_products.forEach(element => {
                    element.selected = false;
                });
            });

            //BUSINESSLOGIC: Añadimos el precio extra de los articulos correspondientes
            let extra_price = detail.extra.reduce((a, b) => {
              a += b.has_price ? b.price : 0;
              return a;
            },0);
            detail.unit_price += extra_price;
            detail.original_price += extra_price;
            detail.subtotal += extra_price;
            detail.iva_percentage = this.product.iva_percentage;
            detail.iva_amount = math.chain(detail.subtotal)
                                    .multiply(detail.iva_percentage / 100) 
                                    .done();
            detail.total =  math.chain(detail.subtotal)
                                .add(detail.iva_amount) 
                                .done();

            console.log('finishWizard', detail)
            this.$emit('finishWizard', detail);
            this.$emit('clean');
        }, 
        onChangeCurrentTab(index) {
            this.stepperIndex = index;
        },
        selectProduct(product) { 
            //* Solo seleccionamos hasta la cantidad maxima
            if (product.selected) {
                this.products[this.steps[this.stepperIndex].data.id] = this.currentProducts.filter(x => x.id != product.id);
                product.selected = false;
            }else if (this.currentProducts.length < this.currentMax){
                this.products[this.steps[this.stepperIndex].data.id].push(product);
                product.selected = true;
            }
        }
    },
    computed: {
        finished() {
            return this.currentMax >= this.currentProducts.length && this.currentMin <= this.currentProducts.length;
        },
        currentMax() {
            return this.steps[this.stepperIndex].data.max_selectable;
        },
        currentMin() {
            return this.steps[this.stepperIndex].data.min_selectable;
        },
        nextButtonEnable() {
            return this.currentProducts.length >= this.currentMin && this.currentProducts.length <= this.currentMax;
        },
        steps() {
            return this.product.details.map((detail, index) => {
                return { 
                    id: index,
                    title: detail.name,
                    data: detail
                }
            });
        },
        currentProducts() {
            return this.products[this.steps[this.stepperIndex].data.id];
        }
    },
    data() {
      return {
        localValue: this.modelValue,
        startIndex: 0,
        stepperIndex: 0,
        products: {

        }
      };
    },
    watch: {
      async localValue(newValue) {
        this.products = {};
        if (this.product) {
            this.product.details.forEach(detail => {
                this.products[detail.id] = [];
            });
        }
        this.$emit('update:modelValue', newValue);
      },
      modelValue(newValue) {
        this.localValue = newValue;
      },
    },
  };
  </script>
    
  <style></style>
    